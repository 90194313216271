import React from 'react';
import {H} from 'highlight.run';
import {i18n} from '@lingui/core';
import {Provider} from 'react-redux';
import {I18nProvider} from '@lingui/react';
import {App, UserInterface} from 'react-ult';
import {isDev, isLocal, isTouch} from 'features/platform';
import {AppFrame} from 'view/AppFrame';
import {VERSION} from 'globals/config';
import store from 'store';

H.init('kgrvv4d6', {
  version: VERSION,
  enableStrictPrivacy: true,
  enablePerformanceRecording: true,
  environment: isDev() ? 'development' : 'production',
  integrations: {
    amplitude: {
      apiKey: '2be406a2444bb2ae880c4e43e25c2906',
    },
  },
	networkRecording: {
		enabled: true,
		recordHeadersAndBody: true,
		urlBlocklist: [
      'https://www.googletagmanager.com/*',
      'https://www.google-analytics.com/*',
      // ...
		],
	},
});

App.initialize(isLocal() || isDev(), isLocal());
UserInterface.useCustomScrollbars(!isTouch());
UserInterface.setMainView(
  <Provider store={store}>
      <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
        <AppFrame/>
      </I18nProvider>
    </Provider>
);
