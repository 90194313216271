import type {UploadEventProgress} from 'features/filesystem/web/lib/upload/types';
import {hashFile, uploadFile, cancelUpload, cancelHashing} from 'features/filesystem';
import {getHost, isWeb} from 'features/platform';
import {uuid, toArray} from 'features/common';
import analytics from 'features/analytics';

const LIMIT_FILE_SIZE = (50 * Math.pow(1024, 3)) + 100; // 50 GiB + 100 byte padding

const STALL_THRESHOLD_FULL = 600000; // 10 minutes (in ms)
const STALL_THRESHOLD_HASH = 30000;  // 30 seconds (in ms)
const STALL_THRESHOLD_VERIFYING = 200000;  // 2 minutes (in ms)

const BATCH_RETRIES = 50;

export type UploadSession = {
  token: string,
  active: boolean,
  files: Map<string, FileUpload>,
  hashes: Map<string, HashState>,
  resolve: UploadResolutions,
  signals: AbortSignal[],
  retries: number[],
  batchRetries: number,
  hasSentNetError?: boolean,
  hasSentHashStall?: boolean,
  hasDisabledHashing?: boolean,
  availableStorage: number,
  queuedStorage: number,
  fallback: {
    active: boolean,
    saved: boolean,
    urls?: FileUpload['urls'],
  },
  watchdog: {
    stallTime: number,
    lastChange: number,
    verifying: number,
    uploaded: number,
    hashed: number,
    stalled: boolean,
  },
  intervals: {
    hash: NodeJS.Timeout,
    check: NodeJS.Timeout,
    upload: NodeJS.Timeout,
    verify: NodeJS.Timeout,
    watchdog: NodeJS.Timeout,
  },
  queues: {
    hash: string[],
    dupe: string[],
    check: string[],
    upload: string[],
    verify: string[],
    active: string[],
  },
  listeners: {
    complete?: UploadListener,
    progress?: UploadListener,
    conflict?: UploadListener,
    failure?: UploadListener,
    failureBatch?: UploadListenerBatch,
  },
}

export type UploadResolutions =
    'prompt'
  | 'keep'
  | 'replace'
  | 'skip_with_errors';

export type UploadListener = (
  data: any,
  file: FileUpload,
  session: UploadSession,
) => unknown;

export type UploadListenerBatch = (
  data: any,
  session: UploadSession,
) => unknown;

export type FileUpload = {
  id: string,
  info: FileInfo,
  state: FileState,
  transfer: FileTransfer,
  urls?: {
    simple: string,
    simple_fallback: string,
    resumable: string,
    resumable_fallback: string,
  },
  hash?: string,
  quickkey?: string,
  duplicateQuickkey?: string,
  exceededFileSize?: boolean,
  exceededStorage?: boolean,
  virusDetected?: boolean,
}

export type FileTransfer = {
  key: string | null,
  hashed: number,
  uploaded: number,
  fails: number,
  exists: boolean,
  conflict: boolean,
  resolution: UploadResolutions,
  resumable?: {
    transferred: boolean,
    unitCount: number,
    unitSize: number,
    unitBitmap: {
      count: number,
      words: string[],
    },
  },
}

export type FileInfo = {
  name: string,
  dest: string,
  type: string,
  size: number,
  path?: string,
  file?: File,
  folder?: string,
}

export enum FileState {
  Queued,
  Hashing,
  Uploading,
  Verifying,
  Completed,
  Duplicate,
  Conflict,
  Aborted,
  Failed,
}

export enum HashState {
  Uploading,
  Completed,
}

export class ReqError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ReqError';
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else { 
      this.stack = (new Error(message)).stack; 
    }
  }
}

export class NetError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NetError';
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else { 
      this.stack = (new Error(message)).stack; 
    }
  }
}

export class StallError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'StallError';
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else { 
      this.stack = (new Error(message)).stack; 
    }
  }
}

export class HashError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'HashError';
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else { 
      this.stack = (new Error(message)).stack; 
    }
  }
}

export class CheckError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'CheckError';
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else { 
      this.stack = (new Error(message)).stack; 
    }
  }
}

export class InstantError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InstantError';
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else { 
      this.stack = (new Error(message)).stack; 
    }
  }
}

export class VerifyError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'VerifyError';
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else { 
      this.stack = (new Error(message)).stack; 
    }
  }
}

export class Uploader {
  /**
   * Creates and returns a new upload session
   * @param token The session token or filedrop key
   * @param token The storage availble in the users account
   */
  static create(token: string, availableStorage: number) {
    const session: UploadSession = {
      token,
      availableStorage,
      queuedStorage: 0,
      files: new Map(),
      hashes: new Map(),
      active: false,
      listeners: {},
      retries: [0, 1000, 3000, 5000, 10000],
      hasDisabledHashing: !Uploader.isHashingSupported(),
      batchRetries: BATCH_RETRIES,
      resolve: 'prompt',
      signals: [],
      fallback: Uploader.loadFallback(),
      watchdog: {
        lastChange: Date.now(),
        stallTime: 0,
        verifying: 0,
        uploaded: 0,
        hashed: 0,
        stalled: false,
      },
      intervals: {
        hash: null,
        check: null,
        upload: null,
        verify: null,
        watchdog: null,
      },
      queues: {
        hash: [],
        dupe: [],
        check: [],
        upload: [],
        verify: [],
        active: [],
      },
    };
    // Replace session token w/ upload action token
    Uploader.authenticate(session);
    // Start processing files added to hash queue
    session.intervals.hash = setInterval(() =>
      Uploader.processHashQueue(session), 200);
    // Start watchdog timer to look for stalls
    session.intervals.watchdog = setInterval(() =>
      Uploader.watchdog(session), 10000);
    return session;
  }

  /**
   * Starts uploading files added to the session
   * @param session the uploader session
   */
  static start(session: UploadSession) {
    if (session.active) return;
    session.active = true;
    session.intervals.check = setInterval(() =>
      Uploader.processCheckQueue(session), 900);
    session.intervals.upload = setInterval(() =>
      Uploader.processUploadQueue(session), 500);
    session.intervals.verify = setInterval(() =>
      Uploader.processVerifyQueue(session), 3000);
  }

  /**
   * Aborts all active uploads and ends the session
   * @param session the uploader session
   */
  static abort(session: UploadSession) {
    // TODO: loop through signals, cancel fetches
    clearInterval(session.intervals.hash);
    clearInterval(session.intervals.check);
    clearInterval(session.intervals.upload);
    clearInterval(session.intervals.verify);
    session.queues.active.forEach(id => {
      const file = session.files.get(id);
      file && Uploader.cancelUpload(file);
    });
    session.files.forEach(file => {
      if (file.state === FileState.Hashing)
        Uploader.cancelHashing(file);
    });
    session.active = false;
    session.files = new Map();
    session.hashes = new Map();
    session.queues = {
      hash: [],
      dupe: [],
      check: [],
      upload: [],
      verify: [],
      active: [],
    };
  }

  /**
   * Add a batch of files to the queue in the upload session
   * @param files list of files to upload
   * @param dest the folder key to upload to
   * @param session the uploader session
   */
  static addFiles(files: FileList | File[], dest: string, session: UploadSession) {
    const hasFile = isWeb();
    toArray(files).forEach(file => {
      const info: FileInfo = {
        dest,
        size: file.size,
        type: file.type,
        name: Uploader.sanitizeName(file.name),
      };
      if (hasFile) {
        info.file = file;
      } else {
        // @ts-ignore-line
        info.path = file.path
      }
      Uploader.addFile(info, session);
    });
  }

  /**
   * Add an event listener to the upload session
   * @param type the upload event to listen for
   * @param listener the upload event callback
   * @param session the uploader session
   */
  static addListener(
    type: keyof UploadSession['listeners'],
    listener: UploadListener & UploadListenerBatch,
    session: UploadSession,
  ) {
    session.listeners[type] = listener;
  }

  /**
   * Handle file conflict (file name already exists at destination)
   * @param file the conflicted file upload
   * @param session the uploader session
   * @param action the conflict resolution to apply
   * @param applyAll whether to apply the resolution to future conflicts
   * 
   */
  static handleConflict(
    file: FileUpload,
    session: UploadSession,
    action: UploadResolutions,
    applyAll?: boolean,
  ) {
    // Called when conflict is chosen
    function updateFile(file: FileUpload) {
      file.state = FileState.Queued;
      file.transfer.resolution = action;
      session.queues.check.unshift(file.id);
      if (session.hashes.has(file.hash)
        && session.hashes.get(file.hash) !== HashState.Completed)
        session.hashes.delete(file.hash);
    }
    // Skipped upload, abort
    if (action === 'skip_with_errors') {
      if (applyAll) {
        session.resolve = action;
        session.files.forEach(upload => {
          if (upload.state === FileState.Conflict) {
            Uploader.sendAbort(upload, session);
          }
        });
      } else {
        Uploader.sendAbort(file, session);
      }
    // Keep both / replace, add to check queue
    } else {
      if (applyAll) {
        session.resolve = action;
        session.files.forEach(upload => {
          if (upload.state === FileState.Conflict) {
            updateFile(upload);
          }
        });
      } else {
        updateFile(file);
      }
    }
    // Start uploader if isn't already active
    Uploader.start(session);
  }

  /**
   * Cancel an active file upload
   * @param file the file upload to cancel
   */
  static async cancelUpload(file: FileUpload) {
    file.state = FileState.Aborted;
    return await cancelUpload(file.id);
  }

  /**
   * Cancel an active file hashing
   * @param file the file upload to cancel
   */
  static async cancelHashing(file: FileUpload) {
    file.state = FileState.Aborted;
    return cancelHashing(file.id);
  }

  // Processing

  private static async processHashQueue(session: UploadSession) {
    // Do nothing if we've disabled hashing
    if (session.hasDisabledHashing) return;
    // Do nothing if no files in the hash queue
    if (session.queues.hash.length === 0) return;
    // Do nothing if no hash slots available
    const slots = Uploader.getFreeHashSlots(session);
    if (slots === 0) return;
    // Sort queue, smallest file first
    session.queues.hash.sort((a: string, b: string) => {
      const fileA = session.files.get(a);
      const fileB = session.files.get(b);
      if (!fileA || !fileB) return 0;
      return fileA.info.size - fileB.info.size;
    });
    // Remove first n upload ids in hash queue (n = slots)
    const ids = session.queues.hash.splice(0, slots);
    ids.forEach(async id => {
      // Lookup file by id
      const file = id && session.files.get(id);
      if (!file) return;
      if (file.hash) return;
      file.state = FileState.Hashing;
      // Hash file
      try {
        analytics.metric('hashStart');
        const input = file.info.file || file.info.path;
        const hash = await hashFile(input, (e) => {
          file.transfer.hashed = e;
          if (session.listeners.progress) {
            session.listeners.progress({
              id: file.id,
              bytesUploaded: 0,
              bytesTotal: file.info.size,
            }, file, session);
          }
        }, file.id);
        analytics.metric('hashComplete');
        file.hash = hash;
        file.transfer.hashed = file.info.size;
        file.state = FileState.Queued;
        // Check if hash is already being uploaded, add
        // to dupe queue if so, otherwise straight to check
        if (session.hashes.get(hash) === HashState.Uploading) {
          session.queues.dupe.push(id);
        } else {
          session.hashes.set(hash, HashState.Uploading);
          session.queues.check.push(id);
        }

        if (session.listeners.progress) {
          session.listeners.progress({
            id: file.id,
            bytesUploaded: 0,
            bytesTotal: file.info.size,
          }, file, session);
        }
      // Failed to hash file, fatal error
      } catch (e) {
        file.transfer.fails = session.retries.length;
        Uploader.sendFailure(e, file, session);
      }
    });
  }

  private static async processUploadQueue(session: UploadSession) {
    // Do nothing if no files in the upload queue
    if (session.queues.upload.length === 0) return;
    // Do nothing if no upload slots available
    const slots = Uploader.getFreeUploadSlots(session);
    if (slots === 0) return;
    // Sort queue, smallest file first
    session.queues.upload.sort((a: string, b: string) => {
      const fileA = session.files.get(a);
      const fileB = session.files.get(b);
      if (!fileA || !fileB) return 0;
      return fileA.info.size - fileB.info.size;
    });
    // Remove first n ids in upload queue (n = free slots)
    const ids = session.queues.upload.splice(0, slots);
    ids.forEach(async id => {
      // Lookup file by id
      const file = id && session.files.get(id);
      if (!file) return;
      file.state = FileState.Uploading;
      session.queues.active.push(file.id);
      // Upload file
      const transferred = await Uploader.transfer(file, session);
      // Remove from active upload queue
      const index = session.queues.active.indexOf(file.id);
      if (index !== -1)
        session.queues.active.splice(index, 1);
      // Add to verification queue
      if (transferred && file.transfer.key) {
        this.sendVerify(file, session);
      } else {
        // Retry failed upload if retries remaining
        const retryDelay = file && session.retries[file.transfer.fails - 1];
        if (retryDelay !== undefined) {
          // Add to top of upload queue after retry delay
          setTimeout(() => {
            session.queues.upload.unshift(file.id);
          }, retryDelay);
        }
      }
    });
  }

  private static async processCheckQueue(session: UploadSession) {
    // Do nothing if no files in the check queue
    if (session.queues.check.length === 0) return;

    // Remove first 100 upload ids in check queue
    const keys = session.queues.check.splice(0, 100);

    // Fetch instant upload response
    const url = `${getHost()}/api/1.5/upload/check.php`;
    const xhr = await Uploader.request(url, {
      uploads: JSON.stringify(keys.map(id => {
        const file = session.files.get(id);
        const hasHash = session.hasDisabledHashing ? 'no' : 'yes';
        return file ? {
          filename: file.info.name,
          folder_key: file.info.dest,
          size: file.info.size,
          hash: file.hash,
          resumable: hasHash,
          preemptive: hasHash,
        } : null;
      })),
    }, session);
    // Entire request failed, add all keys to top of the queue to retry
    if (!xhr) {
      session.queues.check.unshift(...keys);
      keys.forEach((key) => {
        const file = session.files.get(key);
        if (file && session.hashes.has(file.hash)
        && session.hashes.get(file.hash) !== HashState.Completed)
        session.hashes.delete(file.hash);
      })
      return;
    }
    // Conform api response (different properties for single uploads)
    const checks = xhr.res.upload_checks ? xhr.res.upload_checks : [xhr.res];
    if (!checks || checks.length === 0) return;
    // Determine status for every upload in response
    checks.forEach((checkData: any, i: number) => {
      // Lookup file by index then id
      const id = keys[i];
      const file = id && session.files.get(id);
      if (!file) return;
      // File details
      const host = `${getHost()}/api/upload`;
      const hostAlt = 'https://ul.mediafireuserupload.com/api/upload';
      file.urls = checkData.upload_url ?? {
        simple: `${host}/simple.php`,
        simple_fallback: `${hostAlt}/simple.php`,
        resumable: `${host}/resumable.php`,
        resumable_fallback: `${hostAlt}/resumable.php`,
      };
      file.transfer.conflict = checkData.file_exists === 'yes';
      file.transfer.exists = checkData.hash_exists === 'yes';
      file.transfer.key = checkData.resumable_upload ? checkData.resumable_upload.upload_key : '';
      file.transfer.resumable = checkData.resumable_upload ? {
        transferred: checkData.resumable_upload.all_units_ready === 'yes',
        unitCount: parseInt(checkData.resumable_upload.number_of_units),
        unitSize: parseInt(checkData.resumable_upload.unit_size),
        unitBitmap: checkData.resumable_upload.bitmap,
      } : undefined;
      // Preemptive key available
      if (checkData.preemptive_quickkey)
        file.quickkey = checkData.preemptive_quickkey;
      // Duplicate key from conflict
      if (file.transfer.conflict) {
        file.duplicateQuickkey = checkData.duplicate_quickkey;
        // Resolution to skip on conflict
        if (file.transfer.resolution === 'skip_with_errors' || session.resolve === 'skip_with_errors') {
          Uploader.sendAbort(file, session);
          return;
        // Resolution to prompt on conflict
        } else if (file.transfer.resolution === 'prompt' && session.resolve === 'prompt') {
          file.state = FileState.Conflict;
          if (session.listeners.conflict)
            session.listeners.conflict(undefined, file, session);
          return;
        }
      }
      // Update uploader available storage
      session.availableStorage = parseInt(checkData.available_space);
      // File already exists, instant upload
      if (file.transfer.exists) {
        Uploader.instant(file, session);
        return;
      }
      // File already transferred, send to verify
      if (file.transfer?.resumable?.transferred) {
        file.transfer.uploaded = file.info.size;
        Uploader.sendVerify(file, session);
        return;
      }
      // Storage limit exceeded, send failure
      if (checkData.storage_limit_exceeded === 'yes') {
        file.exceededStorage = true;
        file.transfer.fails = session.retries.length;
        Uploader.sendFailure(new CheckError('Not enough storage'), file, session, xhr);
        return;
      // Other error, send failure
      } else if (xhr.res.result === 'Error') {
        file.transfer.fails = session.retries.length;
        Uploader.sendFailure(new CheckError(xhr.res.message), file, session, xhr);
        return;
      }
      // File is uploadable, add to upload queue
      session.queues.upload.push(id);
    });
  }

  private static async processDupeQueue(session: UploadSession) {
    // Do nothing if no files in the dupe queue
    if (session.queues.dupe.length === 0) return;
    // Loop through files in dupe queue, check if the hash was uploaded
    const indexes = [];
    for (let i = 0; i < session.queues.dupe.length; i++) {
      const id = session.queues.dupe[i];
      const file = id && session.files.get(id);
      if (file && session.hashes.get(file.hash) === HashState.Completed) {
        indexes.push(i);
      }
    }
    // Do nothing if no dupe files are ready
    if (indexes.length === 0) return;
    // Move chosen files from dupe to check queue
    indexes.forEach(i => {
      const el = session.queues.dupe.splice(i, 1);
      if (el) session.queues.check.push(el.pop());
    });
  }

  private static async processVerifyQueue(session: UploadSession) {
    // Do nothing if no files in the verify queue
    if (session.queues.verify.length === 0) return;
    // Remove first 100 upload keys in verify queue
    const keys = session.queues.verify.splice(0, 100);
    // Fetch status for the upload keys from the server
    const url = `${getHost()}/api/1.5/upload/poll_upload.php`;
    const xhr = await Uploader.request(url, {key: keys.join()}, session);
    // Entire request failed, add all keys to top of the queue to retry
    if (!xhr) {
      session.queues.verify.unshift(...keys);
      return;
    }
    // Conform api response (different properties for single uploads)
    const uploads = xhr.res.doupload
      ? [{...xhr.res.doupload, key: keys[0]}]
      : xhr.res.douploads;
    if (!uploads || uploads.length === 0) return;
    // Determine status for every upload in response
    uploads.forEach((uploadData: any) => {
      // Lookup file by upload key
      let id: string;
      session.files.forEach(file => {
        if (file.transfer.key === uploadData.key)
          id = file.id;
      });
      if (!id) return;
      const file = session.files.get(id);
      if (!file) return;
      const status = parseInt(uploadData.status, 10);
      const error = parseInt(uploadData.fileerror || 0, 10);
      // Handle upload errors
      switch (error) {
        // Virus infected (do not fail, not fatal)
        case 5:
          file.virusDetected = true;
          break;
        // Max file size exceeded
        case 1:
          file.exceededFileSize = true;
          file.transfer.fails = session.retries.length;
          Uploader.sendFailure(new VerifyError('File too large'), file, session, xhr);
          break;
        // Account storage exceeded
        case 15:
          file.exceededStorage = true;
          file.transfer.fails = session.retries.length;
          Uploader.sendFailure(new VerifyError('Not enough storage'), file, session, xhr);
          break;
        // Other errors...
        default:
          if (error > 0) {
            session.queues.upload.unshift(file.id);
            Uploader.sendFailure(new VerifyError(`Verify error (${error})`), file, session, xhr);
          }
          break;
      }
      // Handle upload status
      switch (status) {
        // Units missing, retry
        case 17:
          session.queues.upload.unshift(file.id);
          Uploader.sendFailure(new VerifyError('Missing units'), file, session, xhr);
          break;
        // File is assembled & verified
        case 98:
        case 99:
          if (error === 0)
            Uploader.sendCompleted(uploadData.quickkey, file, session);
          break;
      }
      // Upload still verifying, add to end of queue
      if (file.state === FileState.Verifying) {
        session.queues.verify.push(uploadData.key);
      }
    });
  }

  // Transfers

  private static async watchdog(session: UploadSession) {
    // Disable watchdog if uploader inactive, no files, or already stalled
    if (!session.active
      || session.files.size === 0
      || session.watchdog.stalled) return;

    // Aggregate state & progress of all files
    let total = 0;
    let hashed = 0;
    let uploaded = 0;

    let hashing: FileUpload[] = [];
    let verifying: FileUpload[] = [];
  
    session.files.forEach(file => {
      total += file.info.size;
      hashed += file.transfer.hashed;
      uploaded += file.state < FileState.Completed
        ? file.transfer.uploaded
        : file.info.size;
      if (file.state === FileState.Hashing)
        hashing.push(file);
      else if (file.state === FileState.Verifying)
        verifying.push(file);
    });
    
    // Uploader state
    const timeNow = Date.now();
    const timeFromChange = timeNow - session.watchdog.lastChange;
    const hasVerifiedChanged = verifying.length !== session.watchdog.verifying;
    const hasUploaded = uploaded > session.watchdog.uploaded;
    const hasHashed = hashed > session.watchdog.hashed;
    const hasProgress = hasHashed || hasUploaded || hasVerifiedChanged;

    const hasStalledFully = !hasProgress
      && uploaded < total
      && hashing.length === 0
      && timeFromChange >= STALL_THRESHOLD_FULL;

    const hasStalledVerifying = !hasVerifiedChanged
      && verifying.length > 0
      && timeFromChange >= STALL_THRESHOLD_VERIFYING;

    const hasStalledHashing = !hasHashed
      && hashed < total
      && hashing.length > 0
      && timeFromChange >= STALL_THRESHOLD_HASH;

    const sessionData = () => {
      let files = {};
      try {
        files = JSON.parse(JSON.stringify(Array.from(session.files.entries())));
      } catch(e) {}
      return {
        active: session.active,
        slots: {
          hash: Uploader.getFreeHashSlots(session),
          upload: Uploader.getFreeUploadSlots(session),
        },
        watchdog: session.watchdog,
        queues: session.queues,
        files,
      };
    };

    // No progress at all, report
    if (hasStalledFully) {
      session.watchdog.stalled = true;
      analytics.notify(new StallError('Uploader stalled'), (e) => {
        e.addMetadata('session', sessionData());
      });
    // No verification progress, report
    } else if (hasStalledVerifying) {
      session.watchdog.lastChange = timeNow;
      analytics.notify(new VerifyError('Verification stalled'), (e) => {
        e.addMetadata('session', sessionData());
      });
    // No hashing progress, restart
    } else if (hasStalledHashing) {
      // Report stall
      session.watchdog.lastChange = timeNow;
      if (!session.hasSentHashStall) {
        session.hasSentHashStall = true;
        analytics.notify(new HashError('Hashing stalled'), (e) => {
          e.addMetadata('session', sessionData());
        });
      }
      // Disable hashing for future uploads
      session.hasDisabledHashing = true;
      // Add all files that were hashing to the check queue, remove from hash queue
      hashing.forEach(file => {
        file.state = FileState.Queued;
        const index = session.queues.hash.indexOf(file.id);
        if (index !== -1) session.queues.hash.splice(index, 1);
        session.queues.check.unshift(file.id);
      });
    // Update watchdog if progress
    } else if (hasProgress) {
      session.watchdog.lastChange = timeNow;
      session.watchdog.uploaded = uploaded;
      session.watchdog.hashed = hashed;
    }
    session.watchdog.stallTime = timeFromChange;
  }
  
  private static async instant(file: FileUpload, session: UploadSession) {
    // Fetch instant upload response
    const url = `${getHost()}/api/1.5/upload/instant.php`;
    analytics.metric('instantStart');
    const xhr = await Uploader.request(url, {
      filename: file.info.name,
      // quickkey: file.duplicateQuickkey,
      folder_key: file.info.dest,
      size: file.info.size,
      hash: file.hash,
    }, session, file);
    // Request failed
    if (!xhr) return;
    // Keep track of quickkey
    let quickkey = xhr.res.quickkey;

    // File hasn't changed, use duplicate quickkey
    if (xhr.res.error === 238)
      quickkey = file.duplicateQuickkey;
    // File infected with virus, flag but continue
    else if (xhr.res.error === 295)
      file.virusDetected = true;

    // File skipped
    if (xhr.res.error === 143) {
      Uploader.sendAbort(file, session);
    // File has quickkey, success!
    } else if (quickkey) {
      analytics.metric('instantComplete');
      Uploader.sendCompleted(quickkey, file, session);
    // No quickkey provided, fail
    } else {
      const message = xhr.res.message || `Error ${xhr.res.error || -1}`;
      Uploader.sendFailure(new InstantError(message), file, session, xhr);
    }
  }

  private static async transfer(file: FileUpload, session: UploadSession) {
    const params = Uploader.getParams({folder_key: file.info.dest}, session, file);
    const query = Uploader.getQuery(params);
    const url = Uploader.getUploadUrl(file, session);
    // Handle upload progress
    const progress = (e: UploadEventProgress) => {
      if (e.resumable)
        file.transfer.resumable = e.resumable;
      file.transfer.uploaded = e.bytesUploaded;
      file.transfer.fails = 0;
      if (session.listeners.progress) {
        session.listeners.progress(e, file, session);
      }
    };
    // Upload file, parse response
    try {
      const target = file.info.file || file.info.path;
      analytics.metric('transferStart');
      const upload = await uploadFile(target as File, `${url}?${query}`, {
        id: file.id,
        hash: file.hash,
        type: file.info.type,
        size: file.info.size,
        name: encodeURIComponent(file.info.name),
        resumable: file.transfer.resumable,
      }, progress);
      // Successful upload
      analytics.metric('transferComplete');
      file.transfer.uploaded = file.info.size;
      file.transfer.key = upload.key;
      // Persist working fallback
      if (session.fallback.active && !session.fallback.saved)
        Uploader.saveFallback(session);
      return true;
    // Failed upload
    } catch (e) {
      if (e?.id && !e?.error) {
        Uploader.sendAbort(file, session);
      } else {
        const error = e?.error ?? e;
        const xhr = {res: e, req: params};
        if (typeof error === 'string') {
          Uploader.sendFailure(new NetError(error), file, session, xhr);
        } else {
          Uploader.sendFailure(error, file, session, xhr);
        }
      }
      return false;
    }
  }

  private static async authenticate(session: UploadSession) {
    // Fetch upload action token
    const url = `${getHost()}/api/1.5/user/get_action_token.php`;
    const opt = {type: 'upload', lifespan: 1440};
    const xhr = await Uploader.request(url, opt, session);
    // Request failed
    if (!xhr || !xhr?.res?.action_token) return;
    session.token = xhr.res.action_token;
  }

  private static async request(url: string, opts: any, session: UploadSession, file?: FileUpload) {
    let xhr: Response;
    let req: any;
    let res: any;
    let json: any;
    try {
      // Build request
      const body = new FormData();
      const params = Uploader.getParams(opts, session, file);
      Object.keys(params).forEach(key => body.append(key, params[key]));
      req = params;
      // Send request
      xhr = await fetch(url, {body, method: 'POST'});
      // Parse response
      json = await xhr.json();
      // Invalid api response, fail
      if (!json || !json.response) {
        const error = new ReqError('Invalid response');
        const xhr = {res: json, req: params};
        // Single file error
        if (file) {
          Uploader.sendFailure(error, file, session, xhr);
        // Batch API error
        } else {
          Uploader.sendBatchFailure(error, session, xhr);
        }
        return false;
      } else {
        res = json.response;
        // Success for batch API, reset retries
        if (!file) {
          session.batchRetries = BATCH_RETRIES;
        }
      }
    // Network error, handle failure
    } catch (err) {
      const status = xhr ? xhr.status : -1;
      const info = {res: {url, status, json}, req}
      // Single file error
      if (file) {
        Uploader.sendFailure(new ReqError(err), file, session, info);
      // Batch API error
      } else {
        Uploader.sendBatchFailure(new ReqError(err), session, info);
      }
      return false;
    }
    // Success, return response
    return {res, req};
  }

  // Dispatches

  private static sendVerify(file: FileUpload, session: UploadSession) {
    file.state = FileState.Verifying;
    session.queues.verify.push(file.transfer.key);
    if (session.listeners.progress) {
      session.listeners.progress({
        id: file.id,
        bytesUploaded: file.info.size,
        bytesTotal: file.info.size,
      }, file, session);
    }
  }

  private static sendCompleted(quickkey: string, file: FileUpload, session: UploadSession) {
    analytics.metric('uploadComplete');
    file.state = FileState.Completed;
    file.transfer.uploaded = file.info.size;
    file.quickkey = quickkey;
    session.queuedStorage -= file.info.size;
    session.hashes.set(file.hash, HashState.Completed);
    Uploader.processDupeQueue(session);
    if (session.listeners.complete) {
      session.listeners.complete(quickkey, file, session);
    }
  }

  private static sendAbort(file: FileUpload, session: UploadSession) {
    analytics.metric('uploadSkip');
    file.state = FileState.Aborted;
    session.queuedStorage -= file.info.size;
    if (session.listeners.failure) {
      session.listeners.failure('Upload skipped', file, session);
    }
  }

  private static sendFailure(error: string | Error | DOMException, file: FileUpload, session: UploadSession, xhr?: {req: any, res: any}) {
    const isNetError = error instanceof NetError;

    try {
      analytics.metric('uploadFail', {error: error.toString()});
    } catch (e) {}

    // Update file state
    file.state = FileState.Failed;
    file.transfer.fails++;

    // Non-network error, send to bugsnag immediately
    if (!isNetError) {
      Uploader.sendReport(error, xhr, file);
    }

    // Network error, second to last retry, enable fallback
    if (isNetError
      && !session.fallback.active
      && file.transfer.fails === session.retries.length - 2) {
      session.fallback.active = true;
      session.fallback.saved = false;
      session.fallback.urls = file.urls;
    }

    // Any error type and ran out of retries
    if (session.retries[file.transfer.fails] === undefined) {
      // Reduce queued storage size to free up upload space
      session.queuedStorage -= file.info.size;
      // Send failure callback
      if (session.listeners.failure) {
        const payload = typeof error === 'string' ? error : error.message;
        session.listeners.failure(payload, file, session);
      }
      // First network error that ran out of retries
      if (isNetError && !session.hasSentNetError) {
        Uploader.sendReport(error, xhr, file);
        session.hasSentNetError = true;
      }
    }
  }

  private static sendBatchFailure(error: Error, session: UploadSession, xhr: {req: any, res: any}) {
    session.batchRetries--;
    if (session.batchRetries === 0) {
      Uploader.sendReport(error, xhr);
      session.listeners.failureBatch(error, session);
    }
  }

  private static sendReport(error: string | Error | DOMException, xhr?: {req: any, res: any}, file?: FileUpload) {
    try {
      analytics.notify(error, e => {
        if (file)
          e.addMetadata('upload', file);
        if (xhr) {
          e.addMetadata('request', xhr.req);
          e.addMetadata('response', xhr.res);
        }
      });
    } catch (e) {}
  }

  // Utilities

  private static addFile(info: FileInfo, session: UploadSession) {
    // For web, set path for folder uploads
    if (isWeb()) {
      const path = info.file['mfRelativePath']
      || info.file['webkitRelativePath']
      || info.file['mozRelativePath'];
      if (path) {
        const fragments = path.split('/')
        fragments.pop();
        info.folder = fragments.join('/');
      }
    }

    // Create upload from provided file info
    const file: FileUpload = {
      info,
      id: uuid(),
      state: FileState.Queued,
      transfer: {
        uploaded: 0,
        hashed: 0,
        fails: 0,
        key: null,
        exists: false,
        conflict: false,
        resolution: 'prompt',
      }
    };

    // Record file by id, add to hash queue
    session.files.set(file.id, file);
    
    // Check if file size exceeds available storage
    if (file.info.size > LIMIT_FILE_SIZE) {
      file.exceededFileSize = true;
      file.transfer.fails = session.retries.length;
      file.state = FileState.Failed;
      if (session.listeners.failure)
        session.listeners.failure('File too large', file, session);
      return;
    }

    // Check if file size exceeds available storage
    if (session.availableStorage === -1 || info.size > session.availableStorage - session.queuedStorage) {
      file.exceededStorage = true;
      file.transfer.fails = session.retries.length;
      file.state = FileState.Failed;
      if (session.listeners.failure)
        session.listeners.failure('Not enough storage', file, session);
      return;
    }

    // Increase used storage by files not yet uploaded
    session.queuedStorage += info.size;

    // Add to hashing or check queue if hashing disabled
    if (session.hasDisabledHashing) {
      session.queues.check.push(file.id);
    } else {
      session.queues.hash.push(file.id);
    }

    // Send first progress update
    analytics.metric('uploadStart');
    if (session.listeners.progress) {
      session.listeners.progress({
        id: file.id,
        bytesUploaded: 0,
        bytesTotal: file.info.size,
      }, file, session);
    }
  }

  private static getUploadUrl(file: FileUpload, session: UploadSession) {
    const urlNormal = file.urls[file.transfer.resumable ? 'resumable' : 'simple'];

    // Using normal urls
    if (!session.fallback.active) {
      return urlNormal;
    }

    // Fallback system
    const actionFallback = file.transfer.resumable ? 'resumable_fallback' : 'simple_fallback';
    const urlFallback = session.fallback.urls[actionFallback];

    // Fallback url mismatch, disable system
    if (urlFallback !== file.urls[actionFallback]) {
      session.fallback.active = false;
      Uploader.saveFallback(session);
      return urlNormal;
    }

    // Using fallback url
    return urlFallback;
  }

  private static getFreeUploadSlots(session: UploadSession) {
    const limitSize = 3E7; // 30MB
    const limitFiles = 5;

    let activeBytes = 0;
    let activeFiles = 0;
    let freeSlots = 0;

    // Look at active uploads to see how many more we can start
    session.queues.active.forEach(id => {
      const file = session.files.get(id);
      if (file) {
        activeFiles++;
        activeBytes += file.info.size;
      }
    });
  
    // Look at next uploads in line to see how many we can start
    for (let i = 0; i < session.queues.upload.length; i++) {
      const id = session.queues.upload[i];
      const file = id && session.files.get(id);
      if (file) {
        // Hashing disable, we'll use a single connection per file, limit by file
        if (session.hasDisabledHashing) {
          if (activeFiles < limitFiles) {
            freeSlots++;
            activeFiles++;
          } else {
            break;
          }
        // Limit slots by size since we'll be using multiple connections per file
        } else {
          if (file.info.size < (limitSize - activeBytes)) {
            freeSlots++;
            activeBytes += file.info.size;
          } else {
            break;
          }
        }
      }
    }

    // No active uploads, uploads queued, no slots free
    // Must be a large file, allow 1 upload slot
    if (session.queues.active.length === 0
      && session.queues.upload.length > 0
      && freeSlots === 0) {
      return 1;
    }

    return Math.min(limitFiles, freeSlots);
  }

  private static getFreeHashSlots(session: UploadSession) {
    const cores = isWeb() && navigator ? navigator.hardwareConcurrency || 1 : 2;
    const limit = Math.max(1, Math.min(8, Math.floor(cores / 2)));
    let active = 0;
    session.files.forEach(file => {
      if (file.state === FileState.Hashing)
        active++;
    });
    return limit - active;
  }

  private static getParams(extra: Record<string, string>, session: UploadSession, file?: FileUpload) {
    const params = {...extra};
    params.response_format = 'json';
    params.session_token = session.token;
    // File folder set, add path (folder upload)
    if (file && file.info.folder)
      params.path = file.info.folder;
    // File conflict resolution set
    if (file && file.transfer.resolution !== 'prompt')
      params.action_on_duplicate = session.resolve;
    // Global conflict resolution set
    else if (session.resolve !== 'prompt')
      params.action_on_duplicate = session.resolve;
    return params;
  }

  private static getQuery(data: Record<string, string>) {
    try {
      return new URLSearchParams(data);
    } catch (e) {
      return Object.keys(data).map((key) =>
        [key, data[key]].map(encodeURIComponent).join('=')
      ).join('&');
    }
  }

  private static saveFallback(session: UploadSession) {
    session.fallback.saved = true;
    try {
      localStorage.setItem('upload_fallback', JSON.stringify(session.fallback));
      return true;
    } catch (e) {
      return false;
    }
  }

  private static loadFallback() {
    const initial = {active: false, saved: false};
    try {
      const serialized = localStorage.getItem('upload_fallback');
      if (!serialized) return initial;
      return JSON.parse(serialized) as UploadSession['fallback'];
    } catch (e) {
      return initial;
    }
  }

  private static sanitizeName(name: string) {
    return unescape(name
      .replace(/\|/g, '-')
      .replace(/\:/g, '-')
      .replace(/\*/g, '-')
      .replace(/\?/g, '-')
      .replace(/\"/g, '-')
      .replace(/\</g, '-')
      .replace(/\>/g, '-')
      .replace(/\//g, '-')
      .replace(/\\/g, '-')
      .trim());
  }

  private static isHashingSupported() {
    return 'FileReader' in self;
  }
}
