import {t} from '@lingui/macro';
import React, {useState, useCallback, useEffect} from 'react';
import {Styles, View, Text, Image, Modal, ActivityIndicator, Storage} from 'react-ult';
import {useDispatch, useSelector} from 'react-redux';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {useModalCreateFolder} from 'hooks/useModalCreateFolder';
import {useModalUpsellProTrial} from 'hooks/useModalUpsellProTrial';
import {useModalUpdatePayment} from 'hooks/useModalUpdatePayment';
import {useModalCustomAlert} from 'hooks/useModalCustomAlert';
import {FilesModalWelcome, modalFilesWelcome} from 'view/modals/FilesModalWelcome';
import {FilesNavigator} from 'view/FilesNavigator';
import {FilesHeader} from 'view/FilesHeader';
import {FilesList} from 'view/FilesList';
import {FilesGrid} from 'view/FilesGrid';
import {FilesPath} from 'view/FilesPath';
import {FilesBar} from 'view/FilesBar';
import {Light, Colors, Breakpoints, Vectors} from 'features/themes';
import {FilesItemFlag} from 'globals/types';
import {isTouch} from 'features/platform';
import {testBit} from 'features/common';
import device from 'features/device';
import images from 'features/images';
import {selectors} from 'store/files';
import * as effects from 'store/files/effects';
import * as app from 'store/app';

export interface FilesProps {
  browser: string;
  content: string;
}

export const Files = React.memo(function Files(props: FilesProps) {
  const [stateDrop, setDrop] = useState(0);
  const isTrial = useSelector(app.selectors.isTrial);
  const isPremium = useSelector(app.selectors.isPremium);
  const isBusiness = useSelector(app.selectors.isBusiness);
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const viewport = useSelector(app.selectors.getViewport);
  const header = useSelector(app.selectors.getHeader);
  const layout = useSelector(selectors.getLayout);
  const query = useSelector(selectors.getQuery);
  const items = useSelector(selectors.getItems);
  const dragging = useSelector(selectors.getDrag);
  const focused = useSelector(selectors.getFocused);
  const selection = useSelector(selectors.getSelection);
  const folder = useSelector(selectors.getItem(props.content));
  const browsed = useSelector(selectors.getItem(props.browser));
  const browser = useSelector(selectors.getView(props.browser));
  const view = useSelector(selectors.getView(props.content));
  const search = useSelector(selectors.getView('search'));
  const recent = useSelector(selectors.getView('recent'));
  const following = useSelector(selectors.getView('following'));
  const hasPrivateSelection = useSelector(selectors.hasPrivateItems(selection));
  const isSearch = useSelector(selectors.isSearch);
  const filter = useSelector(selectors.getFilter);
  const sort = useSelector(selectors.getSort);
  const hasTouch = isTouch();
  const isDarkMode = device.isDarkMode();
  const isRoot = props.content === 'myfiles';
  const isTrash = props.content === 'trash';
  const isRecent = props.content === 'recent';
  const isFollowing = props.content === 'following';
  const isSpecial = isRoot || isFollowing || isRecent || isTrash;
  const isShared = !isSpecial && folder && !testBit(folder.flag, FilesItemFlag.isOwned);
  const isOwned = isLoggedIn && !isShared;
  const isMinimal = viewport.width < 600;
  const hasFullDialog = viewport.width > 600;
  const hasWelcomeDialog = isOwned && !hasTouch && viewport.width >= 800;
  const hasTrialDialog = isOwned && !hasTouch && !isPremium && !isTrial && viewport.width > 600;
  const isLargeScreen = viewport.width > 1120 && viewport.height > 540;
  const isGrid = (layout === 'grid') && !isTrash;
  const sizeActual = view ? view.files.length + view.folders.length : 0;
  const sizeExpected = folder ? (filter.type === 'all' || !view ? folder.files : view.files.length) + (filter.type === 'all' || !view ? folder.folders : view.folders.length) : 0;
  const sizeActualBrowser = browser ? browser.files.length + browser.folders.length : 0;
  const colorPrimary = header ? header.colors.primary : Colors.primary.brand;
  const isLoadingFolder = isRecent ? !recent : isFollowing ? !following : (!folder || sizeActual === 0 && sizeExpected > 0);
  const isLoadingFolderBrowser = !folder || sizeActualBrowser === 0 && (filter.type === 'all' || !browser ? (browsed ? browsed.files + browsed.folders : 0) > 0 : sizeActualBrowser > 0);
  const isLoadingSearch = isSearch && !search && query;
  const hasBreadcrumbs = isOwned;
  const hasHeader = !isOwned && !isTrash && !isLoadingFolder;
  const hasEmptyFolder = !isLoadingFolder && (folder || ((isFollowing || isRecent || isShared) && sizeActual === 0)) && sizeExpected === 0 && !query;
  const hasFolderTree = filter.type !== 'files' && (viewport.width > (isGrid ? Breakpoints.menu : 480)) && isOwned && !hasTouch && !query && !isRecent && !isTrash && !isFollowing;
  const hasEmptyFolderBrowser = !isLoadingFolderBrowser && browsed && browsed.folders === 0;
  const hasEmptySearch = isSearch && !isLoadingSearch && search && (search.files.length === 0 && search.folders.length === 0);
  const hasEmptyUploadTip = !isDarkMode && isLargeScreen && !isTrash && !isRecent && !isShared && !isFollowing;
  const hasListFolder = !isLoadingFolder && !hasEmptyFolder && !query;
  const hasListSearch = !isTrash && !isFollowing && !isLoadingFolder && !hasEmptySearch && !hasListFolder && search;
  const hasSpinner = isLoadingFolder || isLoadingSearch;
  const classes = {
    content: [
      styles.content,
      !isOwned && styles.shared,
      isMinimal && styles.minimal,
    ],
    uploadTip: [
      styles.uploadTip,
      hasEmptyUploadTip && styles.dragDrop,
    ],
    header: [
      styles.header,
      !hasEmptyUploadTip && styles.dragDropHeader,
    ],
  };

  const dispatch = useDispatch();

  const [showCustomAlert] = useModalCustomAlert();
  const [showPaymentUpdate] = useModalUpdatePayment();
  const [showCreateFolder] = useModalCreateFolder(props.browser);
  const [showTrialSignup] = useModalUpsellProTrial(dispatch);

  const welcome = useCallback(() => Modal.show(
    <FilesModalWelcome
      dispatch={dispatch}
      isBusiness={isBusiness}
      isMinimal={!hasFullDialog}
    />,
    modalFilesWelcome), [isBusiness, hasFullDialog]
  );
 
  // Show startup dialogs (welcome, pro trial upsell)
  useEffect(() => {
    try {
      Promise.all([
        Storage.getItem('welcome:shown'),
        Storage.getItem('upsell:trial'),
      ]).then(([welcomeShown, upsellTrial]) => {
        try {
          if (hasWelcomeDialog && welcomeShown !== '1') {
            welcome();
          } else if (hasTrialDialog && upsellTrial !== '1') {
            showTrialSignup();
          }
        } catch (e) {}
      });
    } catch (e) {}
  }, [isOwned, viewport]);

  // Show update payment dialog if needed
  useEffect(() => {
    if (header?.needsPaymentUpdate) {
      showPaymentUpdate();
    }
  }, [header]);
  
  // Show alert dialogs if needed
  useEffect(() => {
    header?.customAlertIDs?.forEach(showCustomAlert);
  }, [header]);

  const dragOver = useCallback(effects.dragOver(), []);
  const dragEnter = useCallback(effects.dragEnter(setDrop, stateDrop), [setDrop, stateDrop]);
  const dragLeave = useCallback(effects.dragLeave(setDrop, stateDrop), [setDrop, stateDrop]);
  const dragDrop = useCallback(effects.dragDrop(dispatch, setDrop, props.content, folder?.name), [setDrop, props.content, folder]);

  return <React.Fragment>
    <FilesBar id={props.content}/>
    <View style={styles.root}>
      {hasFolderTree &&
        <View style={styles.tree}>
          <FilesNavigator id={props.browser || 'myfiles'}/>
          {hasEmptyFolderBrowser &&
            <View style={styles.emptyBrowser}>
              <Text style={styles.emptyBrowserText}>
                {t`You don't have any folders`}
              </Text>
              <Text
                style={styles.emptyCreateText}
                onPress={showCreateFolder}>
                {t`Create a folder`}
              </Text>
            </View>
          }
          {isLoadingFolderBrowser &&
            <View style={styles.loading}>
              <ActivityIndicator color={colorPrimary} size="medium"/>
            </View>
          }
          {!hasEmptyFolderBrowser &&
            <FilesList
              tree
              maxwidth={200}
              sort={sort}
              filter={filter}
              dispatch={dispatch}
              id={props.browser || 'myfiles'}
              name={props.browser && items && items[props.browser]
                ? items[props.browser].name
                : t`My Files`}
              primaryColor={colorPrimary}
              viewport={viewport}
              foreign={!isOwned}
              focusId={undefined}
              dragging={dragging}
              selection={[]}
              items={items}
              view={browser}
              isSearch={isSearch}
              isPremium={isPremium}
            />
          }
        </View>
      }
      <View style={classes.content}>
        {hasHeader &&
          <FilesHeader id={props.content}/>
        }
        {hasBreadcrumbs &&
          <FilesPath
            id={props.content}
            searchQuery={query}
          />
        }
        <View style={styles.lists}>
          {hasListFolder &&
            <View style={styles.list}>
              {isGrid
                ? <FilesGrid
                    sort={sort}
                    filter={filter}
                    maxwidth={isShared ? 1200 : undefined}
                    hasPrivateSelection={hasPrivateSelection}
                    hasFolderTree={hasFolderTree}
                    dispatch={dispatch}
                    id={props.content}
                    name={folder ? folder.name : ''}
                    primaryColor={colorPrimary}
                    foreign={!isOwned}
                    selection={selection}
                    viewport={viewport}
                    dragging={dragging}
                    focusId={focused}
                    items={items}
                    view={view}
                    isSearch={isSearch}
                    isPremium={isPremium}
                  />
                : <FilesList
                    sort={sort}
                    filter={filter}
                    maxwidth={isShared ? 1200 : undefined}
                    hasPrivateSelection={hasPrivateSelection}
                    hasFolderTree={hasFolderTree}
                    dispatch={dispatch}
                    id={props.content}
                    name={folder ? folder.name : ''}
                    primaryColor={colorPrimary}
                    foreign={!isOwned}
                    selection={selection}
                    viewport={viewport}
                    dragging={dragging}
                    focusId={focused}
                    items={items}
                    view={view}
                    isSearch={isSearch}
                    isPremium={isPremium}
                  />
              }
            </View>
          }
          {hasListSearch && !hasEmptyFolder &&
            <View style={styles.list}>
              <FilesList
                id="search"
                name={t`Search`}
                sort={sort}
                filter={filter}
                primaryColor={colorPrimary}
                dispatch={dispatch}
                selection={selection}
                viewport={viewport}
                dragging={dragging}
                focusId={focused}
                items={items}
                view={search}
                isSearch={isSearch}
                isPremium={isPremium}
                hasPrivateSelection={hasPrivateSelection}
              />
            </View>
          }
          {hasEmptySearch &&
            <View style={styles.center}>
              <View style={styles.searchEmpty}>
                <Svg viewBox={'0 0 200 200'} width={200} height={200}>
                  <SvgPath d={Vectors.searchNotFound[0]} fillColor="#8AC5FF"/>
                  <SvgPath d={Vectors.searchNotFound[1]} fillColor="#D2E9FF"/>
                  <SvgPath d={Vectors.searchNotFound[2]} fillColor="#99CCFF"/>
                  <SvgPath d={Vectors.searchNotFound[3]} fillColor="#A6D3FF"/>
                  <SvgPath d={Vectors.searchNotFound[4]} fillColor="#C0E0FF"/>
                  <SvgPath d={Vectors.searchNotFound[5]} fillColor="#D2E9FF"/>
                  <SvgPath d={Vectors.searchNotFound[6]} fillColor="#0070F0"/>
                  <SvgPath d={Vectors.searchNotFound[7]} fillColor="#80C0FF"/>
                </Svg>
                <Text style={styles.header}>
                  {t`No results found`}
                </Text>
                <Text style={styles.footer}>
                  {t`Sorry, we looked but didn't find anything.`}
                </Text>
              </View>
            </View>
          }
          {hasEmptyFolder &&
            <View
              style={styles.center}              
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDragOver={dragOver}
              onDrop={dragDrop}>
              <View style={classes.uploadTip}>
                {hasEmptyUploadTip &&
                  <Image style={styles.uploadTipBg} source={images.emptyBackground}/>
                }
                {hasEmptyUploadTip &&
                  <Image style={styles.uploadTipDrag} source={images.emptyDrag}/>
                }
                <Text style={classes.header}>
                  {isRecent
                    ? t`No recent files`
                    : isFollowing
                      ? t`You are not following anything`
                      : isTrash
                        ? t`Trash is empty`
                        : t`This folder is empty`
                  }
                </Text>
                {hasEmptyUploadTip &&
                  <Text style={styles.footer}>
                    {t`Upload files or folders by clicking the upload button, or drag &amp; drop from your device.`}
                  </Text>
                }
              </View>
            </View>
          }
          {hasSpinner &&
            <View style={styles.loading}>
              <ActivityIndicator color={colorPrimary} size="medium"/>
            </View>
          }
        </View>
      </View>
    </View>
  </React.Fragment>
});

export const styles = {
  root: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: Light.filesBackground,
  }),
  content: Styles.createViewStyle({
    flex: 1,
  }),
  shared: Styles.createViewStyle({
    maxWidth: 1200,
  }),
  minimal: Styles.createViewStyle({
    marginHorizontal: 0,
  }),
  lists: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row',
  }),
  list: Styles.createViewStyle({
    flex: 1,
    margin: 0,
  }),
  tree: Styles.createViewStyle({
    width: 200,
    flex: 0,
    margin: 0,
    borderRightWidth: 1,
    borderColor: Light.filesTreeBorder,
    backgroundColor: Light.filesTreeBackground,
  }),
  query: Styles.createTextStyle({
    color: Colors.neutral.mid,
    fontSize: 24,
    margin: 20,
  }),
  title: Styles.createTextStyle({
    color: Colors.neutral.mid,
    fontSize: 24,
    margin: 20,
  }),
  header: Styles.createTextStyle({
    textAlign: 'center',
    color: Colors.neutral.light,
    fontSize: 26,
  }),
  footer: Styles.createTextStyle({
    textAlign: 'center',
    color: Colors.neutral.mid,
    fontSize: 14,
    marginTop: 16,
  }),
  emptyBrowser: Styles.createViewStyle({
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  emptyBrowserText: Styles.createTextStyle({
    color: Colors.neutral.light,
    fontSize: 14,
  }),
  emptyCreateText: Styles.createTextStyle({
    color: Colors.neutral.mid,
    textDecorationLine: 'underline',
    cursor: 'pointer',
    fontSize: 12,
    marginTop: 6,
  }),
  center: Styles.createViewStyle({
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  }),
  loading: Styles.createViewStyle({
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  searchEmpty: Styles.createViewStyle({
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    flex: 1,
  }),
  dragDrop: Styles.createViewStyle({
    width: 640,
    height: 360,
    padding: 36,
    borderRadius: 5,
    borderWidth: 5,
    borderStyle: 'dashed',
    borderColor: '#E6E6E6',
    position: 'relative',
  }),
  dragDropHeader: Styles.createTextStyle({
    textAlign: 'center',
    color: Colors.neutral.light,
    fontSize: 26,
  }),
  uploadTip: Styles.createViewStyle({
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  }),
  uploadTipBg: Styles.createImageStyle({
    width: 559,
    height: 150,
    marginBottom: 26,
  }),
  uploadTipDrag: Styles.createImageStyle({
    position: 'absolute',
    top: 100,
    left: 300,
    width: 58,
    height: 46,
    opacity: 1,
  }),
};
