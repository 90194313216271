import Bugsnag, {NotifiableError, OnErrorCallback, Event} from '@bugsnag/js';
import {H} from 'highlight.run';
import {KEYS, VERSION} from 'globals/config';
import {AppUserGroup} from 'globals/types';
import {AnalyticsBase} from './models';

const gtag = typeof window['gtag'] !== 'undefined' ? window['gtag'] : () => {};
const loaded = {bugsnag: false};
const user = {id: '', group: AppUserGroup.Unregistered};

class Analytics implements AnalyticsBase {
  start(appName: string, userId: string, userGroup: AppUserGroup) {
    user.id = userId;
    user.group = userGroup;

    H.identify(userId, {
      plan: userGroup < AppUserGroup.Free
        ? 'trial'
        : userGroup === AppUserGroup.Business
          ? 'business'
          : userGroup === AppUserGroup.Pro
            ? 'premium'
            : 'free',
    });

    // Bugsnag
    try {
      Bugsnag.start({
        apiKey: KEYS.BUGSNAG,
        appVersion: VERSION,
        appType: process.env.ULT_APP_STAGE,
        user: userId ? {id: userId} : undefined,
        context: 'myfiles',
        maxBreadcrumbs: 50,
        enabledBreadcrumbTypes: ['log', 'user', 'state', 'error', 'manual', 'process', 'request'],
        onError: async (event: Event) => {
          event.addMetadata('services', {
            highlight: await H.getSessionURL(),
          })
        },
      });
      loaded.bugsnag = true;
    } catch (e) {}

    // Google Analytics
    try {
      gtag('config', {
        user_id: userId,
        dimension7: appName,
        dimension1: userGroup < AppUserGroup.Free
          ? 'unregistered'
          : userGroup === AppUserGroup.Free
            ? 'basic'
            : 'premium',
      });
    } catch (e) {}
  }

  metric(name: string, params?: {[key: string]: any}) {
    H.track('metric/' + name, params);
    H.metrics([{name, value: 1}]);
    try {
      gtag('event', name, params);
    } catch (e) {}
  }

  screen(name: string) {
    try {
      gtag('event', 'screen_view', {screen_name: name});
    } catch (e) {}
  }

  log(value: string, metadata?: {[key: string]: any}) {
    loaded.bugsnag && Bugsnag.leaveBreadcrumb(value, metadata);
    H.track(value, metadata);
  }

  notify(error: NotifiableError, onError?: OnErrorCallback) {
    if (!loaded.bugsnag) return;
    Bugsnag.notify(error, onError);
    H.consumeError(error as Error);
  }
}

export default new Analytics();
